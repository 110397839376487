import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { credentialsUser, ResponseAuthentication, TwoFactorDto } from '@app/models/RiskallayUser';
import jwt_decode from 'jwt-decode';
import { catchError, map, skip } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { ActiveCampaignService } from './active-campaign.service';

@Injectable()
export class AuthenticationService {

    private authenticatedChanged: BehaviorSubject<boolean>;
    public account: Observable<boolean>;
    private readonly refreshTokenKey = 'RefreshToken';
    private readonly llaveToken = 'token';
    private readonly campoRol = 'role';
    private readonly campoClient = 'Client';

    private readonly currentUser = 'currentUser';
    private refreshTokenTimeout;

    constructor(
        private router: Router,
        private http: HttpClient,
        private globalService: GlobalService,
        private activeCampaignService: ActiveCampaignService,
        @Inject('API_URL') private serverUrl: string) {
        this.authenticatedChanged = new BehaviorSubject<boolean>(null);
        this.account = this.authenticatedChanged.asObservable();
    }

    public get accountValue(): boolean {
        return this.authenticatedChanged.value;
    }

    async authenticate(username: string, password: string) {
        let credentials: credentialsUser;
        credentials = {
            Email: username,
            password: password
        };
        try {
            const response = await this.http.post<ResponseAuthentication>(
                this.createCompleteRoute('Account/login', this.serverUrl), credentials, { withCredentials: true, observe: 'response' as 'response' }).toPromise();

            if (response.body.Is2StepVerificationRequired) {
                return response;
            } else if (response.body.Token) {
              if(response.body[this.refreshTokenKey]){
                this.setRefreshToken(response.body[this.refreshTokenKey] as string)
              }else{
                this.setRefreshToken(response.body.Token)
              }
              localStorage.setItem(this.llaveToken, response.body.Token);
              localStorage.setItem(this.currentUser, username.toLowerCase());
              this.startRefreshTokenTimer(parseInt(this.getFieldJWT('exp')));
              this.authenticatedChanged.next(true);
              return response;
            } else {
                return null;
            }
        } catch (e) {
            return e;
        }
    }

    getToken() {
        const token = localStorage.getItem(this.llaveToken);
        if (token) {
            return token;
        } else {
            this.authenticatedChanged.next(null);
            return null;
        }
    }

    getRequest(url) {
        const token = this.getToken();

        if (token) {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');

            const headerObj = {
                headers: headers
            };

            return this.http.get<any>(url, headerObj);
        } else {
            return null;
        }
    }

    logout() {
        this.globalService.closeAllNav();
        this.activeCampaignService.closeCampaign();

        this.http.post<any>(this.createCompleteRoute('Account/RevokeToken', this.serverUrl), {}, { withCredentials: true })
          .subscribe(() => {}, () => {});
        this.stopRefreshTokenTimer();
        localStorage.removeItem(this.llaveToken);
        localStorage.removeItem(this.currentUser);
        this.authenticatedChanged.next(null);
        this.router.navigate(['/login']);
    }

    getRoles(): string[] {
        const token = localStorage.getItem(this.llaveToken);
        if (!token) { return []; }
        const dataToken = jwt_decode(token);
        return dataToken[this.campoRol];
    }

    getClientId(): number {
      const token = localStorage.getItem(this.llaveToken);
      if (!token) { return -1; }
      const dataToken = jwt_decode(token);
      return +dataToken[this.campoClient];
    }

    isSaeta(): boolean {
      const saetaClientID = 68 //EN PRO - Tabla CLients Id de Saeta Yield
     return this.getClientId() === saetaClientID;
    }

    getFieldJWT(field: string): string {
        const token = localStorage.getItem(this.llaveToken);
        if (!token) { return ''; }
        const dataToken = jwt_decode(token);
        return dataToken[field];
    }

    setRefreshToken(refreshToken:string){
      localStorage.setItem(this.refreshTokenKey, refreshToken);
    }

    getRefreshToken(){
      return localStorage.getItem(this.refreshTokenKey) ??  "";
    }

    refreshToken() {
        const token = this.getRefreshToken();
        return this.http.post<ResponseAuthentication>(
            this.createCompleteRoute('Account/RefreshToken', this.serverUrl), null, { headers:{RefreshToken: token}, withCredentials: true })
            .pipe(map((response) => {
                if (response.Token) {
                    if(response[this.refreshTokenKey]){
                      this.setRefreshToken(response[this.refreshTokenKey] as string)
                    }else{
                      this.setRefreshToken(response.Token)
                    }
                    localStorage.setItem(this.llaveToken, response.Token);
                    this.startRefreshTokenTimer(parseInt(this.getFieldJWT('exp')));
                    this.authenticatedChanged.pipe(skip(this.authenticatedChanged.observers.length))
                    this.authenticatedChanged.next(true);

                    return 'OK';
                } else {
                    this.logout();
                }
            }),
          catchError((error) => {
            return error;
          }));
    }

    async twoStepLogin(body: TwoFactorDto) {
        try {
            const response = await this.http.post<ResponseAuthentication>(
                this.createCompleteRoute('Account/TwoStepVerification', this.serverUrl), body, { withCredentials: true }).toPromise();
            if (response.Token) {
                localStorage.setItem(this.llaveToken, response.Token);
                localStorage.setItem(this.currentUser, body.Email.toLowerCase());
                this.startRefreshTokenTimer(parseInt(this.getFieldJWT('exp')));
                this.authenticatedChanged.next(true);
            }

            return response;

        } catch (error) {

        }
    }

    private createCompleteRoute = (route: string, envAddress: string) => {
        return `${envAddress}/${route}`;
    }

    private startRefreshTokenTimer(expires: number) {
        const timeout = new Date(expires*1000).getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
